/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');

// window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });

// function removeNode(id){
//     $("#" + id).remove();
// }

var searchHandlers = {};
var searchDelay = 400;

/**
 * Add a URL parameter (or modify if already exists)
 * @param {string} url 
 * @param {string} param the key to set
 * @param {string} value 
 */
setParam = function (url, param, value) {

    if (!url) {
        url = document.location.href;
    }

    var TheAnchor = null;
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";

    if (additionalURL) {
        var tmpAnchor = additionalURL.split("#");
        var TheParams = tmpAnchor[0];
        TheAnchor = tmpAnchor[1];
        if (TheAnchor)
            additionalURL = TheParams;

        tempArray = additionalURL.split("&");

        for (var i = 0; i < tempArray.length; i++) {
            if (tempArray[i].split('=')[0] != param) {
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    } else {
        var tmpAnchor = baseURL.split("#");
        var TheParams = tmpAnchor[0];
        TheAnchor = tmpAnchor[1];

        if (TheParams)
            baseURL = TheParams;
    }

    if (TheAnchor)
        paramVal += "#" + TheAnchor;

    var rows_txt = temp + "" + param + "=" + value;
    return baseURL + "?" + newAdditionalURL + rows_txt;
}

doSearchServer = function (filter) {
    var href = setParam(null, "filter", filter);
    href = setParam(href, "page", null);
    document.location = href;
}

doSearchClient = function ($wrapper, filter) {
    alert("Client filter ist noch nicht implementiert");
}

document.onFilterTableChanged = function (e) {
    var $search = $(e.currentTarget);
    var id = $search.attr("id");
    var filter = $search.val();
    var $wrapper = $search.closest("[data-table-wrapper]");
    var mode = $wrapper.data("table-wrapper");

    if (searchHandlers[id]) clearTimeout(searchHandlers[id]);
    searchHandlers[id] = setTimeout(function () {
        if (mode == "server") {
            doSearchServer(filter);
        } else {
            doSearchClient($wrapper, filter);
        }
    }, searchDelay);
};
